<template>
	<div></div>
</template>

<script>
import eventBus from '@/utils/eventBus'
import { mapMutations, mapState } from 'vuex'
import { SessionStorage } from 'storage-manager-js'

export default {
	name: 'mixins_wallet_connect',
	data () {
		return {}
	},
	computed: {
		...mapState(['ADDRESS']),
	},
	methods: {
		...mapMutations(['setADDRESS']),
		async connect_wallet () {
			if (this.ethereum.isConnected()) {
				try {
					const accounts = await this.ethereum.send('eth_requestAccounts')
					console.log('钱包连接成功：', accounts)
					const { result } = accounts
					this.setADDRESS(result[0])
					this.callGlobalConnectEvent(result[0])
				} catch (error) {
					console.error(error)
				}
			} else {
				const accounts = await this.web3.eth.getAccounts()
				this.setADDRESS(accounts[0])
				this.callGlobalConnectEvent(accounts[0])
			}
			SessionStorage.set('ADDRESS', this.ADDRESS)
			this.dialog = false
		},
		async getBalance () {
			const data = await this.web3.eth.getBalance(this.ADDRESS)
			console.log(data)
		},
		on_disconnect () {
			this.ethereum.on('disconnect', (error) => {
				console.log('钱包断开连接：', error)
			})
		},
		on_chain_changed () {
			this.ethereum.on('chainChanged', (chainId) => {
				console.log('chainChanged: ', chainId)
				window.location.reload()
			})
		},
		on_accounts_changed () {
			this.ethereum.on('accountsChanged', (accounts) => {
				console.log('钱包账户切换：', accounts)
				if (accounts.length > 0) {
					this.setADDRESS(accounts[0])
				} else {
					this.setADDRESS(null)
				}
			})
		},
		callGlobalConnectEvent (address) {
			if (address !== undefined) {
				eventBus.$emit('connect-event', address)
			}
		},
	},
}
</script>

<style scoped>

</style>
