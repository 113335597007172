<template>
	<v-app>
		<v-navigation-drawer v-model="drawer" clipped width="240px" app>
			<div class="menu-box">
				<div v-for="(item, index) in menuList" :key="index">
					<div class="menu-item" v-if="item.url.indexOf('https') > -1">
						<a class="menu-item__a" :href="item.url">
							<div class="d-flex align-center">
								<img width="24" :src="item.lightIcon" alt="" v-if="item.url !== '/app' && $route.path.indexOf(item.url) !== -1 && item.url !== '/Game'">
								<img width="24" :src="item.darkIcon" alt="" v-else>
								<span>{{ item.title }}</span>
							</div>
							<v-icon color="#67717A" class="mr-4 icon-rotate__transition"
											:class="{'icon-rotate__n180': $route.path.indexOf('/Game') !== -1}" v-if="item.subItems">
								mdi-chevron-down
							</v-icon>
						</a>
					</div>
					<router-link :to="item.url" tag="div" class="menu-item" exact v-else>
						<div class="menu-item__a">
							<div class="d-flex align-center">
								<img width="24" :src="item.lightIcon" alt="" v-if="item.url !== '/app' && $route.path.indexOf(item.url) !== -1 && item.url !== '/app/Game'">
								<img width="24" :src="item.darkIcon" alt="" v-else>
								<span>{{ item.title }}</span>
							</div>
							<v-icon color="#67717A" class="mr-4 icon-rotate__transition"
											:class="{'icon-rotate__n180': $route.path.indexOf('/Game') !== -1}" v-if="item.subItems">
								mdi-chevron-down
							</v-icon>
						</div>
						<div class="menu-item__sub" v-show="$route.path.indexOf('/app/Game') !== -1">
							<div v-for="(subItem, i) in item.subItems" :key="`${index}_${i}`" @click.stop="_to(subItem.url)">
								<div class="menu-item__a">
									<div class="d-flex align-center">
										<!--						<img width="24" :src="subItem.lightIcon" alt="" v-if="subItem.url && $route.path.indexOf(subItem.url) !== -1">
																<img width="24" :src="subItem.darkIcon" alt="" v-else>-->
										<span :class="{'white--text': subItem.url && $route.path.indexOf(subItem.url) !== -1}">{{
												subItem.title
											}}</span>
									</div>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			<!--			<template v-slot:append>
							<div style="border-top: 1px solid #2D3339;"></div>
							<div class="menu-foot">
								<div class="menu-foot__head d-flex align-center mb-10">
									<img class="mr-3" width="24" src="@/assets/logo.svg" alt="">
									<span>$0.00</span>
								</div>
								<div class="menu-foot__link d-flex align-center justify-space-between">
									<a href="" v-for="(item, i) in links" :key="i">
										<img :src="item.icon" alt="">
									</a>
								</div>
							</div>
						</template>-->
		</v-navigation-drawer>
		<v-app-bar
				class="c-app-bar"
				height="64px"
				app
				clipped-left
				elevation="0"
		>
			<img class="mr-5" width="24" src="@/assets/menu.svg" alt="" @click="toggleMenu">
			<img width="32" src="@/assets/logo.png" alt="" v-if="!is_mobile">
			<span class="ml-3" v-if="!is_mobile">Amateras</span>
			<!--      <v-switch
							v-model="$vuetify.theme.dark"
							hint="This toggles the global state of the Vuetify theme"
							inset
							label="Vuetify Theme Dark"
							persistent-hint
						></v-switch>-->
			<v-spacer></v-spacer>
			<v-menu
					open-on-hover
					top
					offset-y
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
							class="mx-2"
							text
							v-bind="attrs"
							v-on="on"
							color="#EB6930"
					>{{ language }}
						<v-icon
								right
								size="24"
								color="#EB6930"
						>
							mdi-web
						</v-icon>
					</v-btn>
				</template>

				<v-list>
					<v-list-item
							@click="languageTap(item)"
							v-for="(item, index) in languageItems"
							:key="index"
					>
						<v-list-item-title>{{ item.title }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
			<div v-if="ADDRESS" class="app-connect__box" @click="account_dialog = true">
				<!--        <img class="app-connect__img" width="36" src="@/assets/wallet.svg" alt="">-->
				{{ addressSub }}
			</div>
			<v-btn
					elevation="0"
					dark
					rounded
					small
					color="#EB6930"
					@click="dialog = true"
					v-else
			>{{ $t('connect') }}
			</v-btn>
		</v-app-bar>
		<v-main>
			<v-container class="c-app-container">
				<router-view/>
			</v-container>
			<v-footer class="d-flex align-center justify-center pa-0" height="80">
				<div class="foot" style="padding: 0 25px;">Copyright @ 2021 Amaterasu All Rights Reserved</div>
			</v-footer>
		</v-main>

		<v-row justify="center" v-show="dialog">
			<v-dialog
					content-class="connect-dialog"
					v-model="dialog"
					width="320px"
			>
				<div class="connect-box">
					<div class="content-head">
						<span>{{ $t('Connect to a wallet') }}</span>
						<v-btn small @click="dialog = false" icon>
							<v-icon color="#eb6930">mdi-close</v-icon>
						</v-btn>
					</div>
					<div class="connect-content">
						<div class="connect-wallet__item" v-for="item in walletList" :key="item.name" @click="connect_wallet">
							<span class="connect-wallet__item--title">{{ item.name }}</span>
							<img :src="item.logo" class="connect-wallet__item--logo" alt="">
						</div>
						<!--            <div class="connect-content__info">Connected wallet does not touch your assets, your assets are still
													controlled through your own private key, we will not transfer any assets to your wallet address without
													your permission
												</div>
												<a href="https://ethereum.org/en/wallets/" target="_blank" class="connect-content__more">Learn more about
													wallets</a>-->
					</div>
				</div>
			</v-dialog>
		</v-row>

		<v-dialog v-model="account_dialog" max-width="500px">
			<div class="account-dialog">
				<div class="account-dialog_head">
					<div class="account-dialog_title">{{ $t('Your wallet') }}</div>
					<v-icon color="#E95A29" @click="account_dialog = false">mdi-close</v-icon>
				</div>

				<div class="account-dialog_content">
					<div class="account-dialog_address">{{ ADDRESS }}</div>
					<input class="account-dialog_input" id="address" :value="ADDRESS">
					<div class="mb-8 d-flex align-center">
						<div class="mr-4">
							<span class="account-dialog_text">{{ $t('View on BscScan') }}</span>
							<a class="ml-1" target="_blank" :href="`https://bscscan.com/address/${ADDRESS}`">
								<v-icon color="#E95A29">mdi-open-in-new</v-icon>
							</a>
						</div>
						<div>
							<span class="account-dialog_text">{{ $t('Copy Address') }}</span>
							<button data-clipboard-target="#address" class="ml-1 copy-btn">
								<v-icon color="#E95A29">mdi-content-copy</v-icon>
							</button>
						</div>
					</div>
					<div class="text-center">
						<v-btn outlined rounded color="#E95A29" @click="logout">{{ $t('Logout') }}</v-btn>
					</div>
				</div>
			</div>
		</v-dialog>
	</v-app>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import mixins_wallet_connect from '@/mixins/mixins_wallet_connect'
import ClipboardJS from 'clipboard'
import { SessionStorage } from 'storage-manager-js'

export default {
	name: 'ViewBox',
	data () {
		return {
			drawer: false,
			account_dialog: false,
			language: 'EN',
			languageList: {
				EN: 'en',
				JA: 'ja',
				KO: 'ko',
				'ZH-CN': 'zhHans',
				'ZH-TW': 'zhHant',
			},
			languageItems: [
				{
					title: 'English',
					value: 'EN',
				},
				{
					title: '日本語',
					value: 'JA',
				},
				{
					title: '한국어',
					value: 'KO',
				},
				{
					title: '简体中文',
					value: 'ZH-CN',
				},
				{
					title: '繁體中文',
					value: 'ZH-TW',
				},
			],
			right: null,
			dialog: false,
			selectedItem: 1,
			walletList: [
				{
					name: 'MetaMask',
					logo: require('@/assets/metaMask.svg'),
				},
				{
					name: 'TrustWallet',
					logo: require('@/assets/trustWallet.svg'),
				},
				{
					name: 'MathWallet',
					logo: require('@/assets/mathWallet.svg'),
				},
				{
					name: 'TokenPocket',
					logo: require('@/assets/tokenPocket.svg'),
				},
			],
			connector: null,
			links: [
				{
					icon: require('@/assets/feiji.svg'),
					url: '',
				},
				{
					icon: require('@/assets/Twitter.svg'),
					url: '',
				},
				{
					icon: require('@/assets/Frame.svg'),
					url: '',
				},
				{
					icon: require('@/assets/medium.svg'),
					url: '',
				},
			],
		}
	},
	mixins: [mixins_wallet_connect],
	created () {
		// this.connect_wallet()
		this.language = SessionStorage.get('LANGUAGE') || 'EN'
		this.on_accounts_changed()
		this.on_chain_changed()
		// this.on_disconnect()
	},
	computed: {
		...mapState(['ADDRESS']),
		...mapGetters(['is_connected']),
		addressSub () {
			return this.ADDRESS ? `${this.ADDRESS.slice(0, 4)}...${this.ADDRESS.substr(-4)}` : ''
		},
		is_mobile () {
			return this.$vuetify.breakpoint.mobile
		},
		menuList () {
			return [
				{
					title: this.$t('Dashboard'),
					darkIcon: require('@/assets/menu/dark/airplay.svg'),
					lightIcon: require('@/assets/menu/airplay.svg'),
					url: '/app',
				},
				{
					title: this.$t('Swap'),
					darkIcon: require('@/assets/menu/dark/SWAP.svg'),
					lightIcon: require('@/assets/menu/SWAP.svg'),
					url: 'https://swap.amateras.io/#/swap',
				},
				{
					title: this.$t('Stake'),
					darkIcon: require('@/assets/menu/dark/STAKE.svg'),
					lightIcon: require('@/assets/menu/STAKE.svg'),
					url: 'https://farms.amateras.io/farms',
				},
				{
					title: this.$t('Mining Pool'),
					darkIcon: require('@/assets/menu/dark/MiningPool.svg'),
					lightIcon: require('@/assets/menu/MiningPool.svg'),
					url: '/app/MiningPool',
				},
				{
					title: this.$t('Farming Pool'),
					darkIcon: require('@/assets/menu/dark/FarmingPool.svg'),
					lightIcon: require('@/assets/menu/FarmingPool.svg'),
					url: '/app/FarmingPool',
				},
				{
					title: this.$t('Bonuses Pool'),
					darkIcon: require('@/assets/menu/dark/BonusesPool.svg'),
					lightIcon: require('@/assets/menu/BonusesPool.svg'),
					url: '/app/BonusesPool',
				},
				// {
				// 	title: 'Airdrop',
				// 	darkIcon: require('@/assets/menu/dark/Airdrop.svg'),
				// 	lightIcon: require('@/assets/menu/Airdrop.svg'),
				// 	// url: '/Airdrop',
				// 	url: '/',
				// },
				{
					title: this.$t('Referral'),
					darkIcon: require('@/assets/menu/dark/Referral.svg'),
					lightIcon: require('@/assets/menu/Referral.svg'),
					url: '/app/Referral',
				},
				// {
				// 	title: 'Buring',
				// 	darkIcon: require('@/assets/menu/dark/Buring.svg'),
				// 	lightIcon: require('@/assets/menu/Buring.svg'),
				// 	// url: '/Buring',
				// 	url: '/',
				// },
				{
					title: this.$t('NFT Market'),
					darkIcon: require('@/assets/menu/dark/NTFmarket.svg'),
					lightIcon: require('@/assets/menu/NTFmarket.svg'),
					url: '/app/NFTMarket',
				},
				// {
				// 	title: this.$t('Auction'),
				// 	darkIcon: require('@/assets/menu/dark/Auction.svg'),
				// 	lightIcon: require('@/assets/menu/Auction.svg'),
				// 	url: '/app/AuctionList',
				// },
				// {
				// 	title: this.$t('Buy UR'),
				// 	darkIcon: require('@/assets/menu/dark/BuyUR.svg'),
				// 	lightIcon: require('@/assets/menu/BuyUR.svg'),
				// 	url: '/app/BuyUR',
				// },
				{
					title: this.$t('Game'),
					darkIcon: require('@/assets/menu/dark/Game.svg'),
					lightIcon: require('@/assets/menu/Game.svg'),
					url: '/app/Game',
					subItems: [
						{
							title: this.$t('Buy Box'),
							darkIcon: require('@/assets/menu/dark/buybox.svg'),
							lightIcon: require('@/assets/menu/buybox.svg'),
							url: '/app/Game/BuyBox',
						},
						{
							title: this.$t('My NFT'),
							darkIcon: require('@/assets/menu/dark/Game-2.svg'),
							lightIcon: require('@/assets/menu/Game-2.svg'),
							url: '/app/Game/MyNFT',
						},
						// {
						// 	title: 'LEADERBOARD',
						// 	darkIcon: require('@/assets/menu/dark/Game-3.svg'),
						// 	lightIcon: require('@/assets/menu/Game-3.svg'),
						// 	// url: '/Game/Leaderboard',
						// 	url: '',
						// },
						{
							title: this.$t('DISPLAY'),
							darkIcon: require('@/assets/menu/dark/Game-4.svg'),
							lightIcon: require('@/assets/menu/Game-4.svg'),
							url: '/app/Game/Display',
						},
						{
							title: this.$t('COMBINE GUIDE'),
							darkIcon: require('@/assets/menu/dark/Game-5.svg'),
							lightIcon: require('@/assets/menu/Game-5.svg'),
							url: '/app/Game/CombineGuide',
						},
					],
				},
				// {
				// 	title: this.$t('FAQ'),
				// 	darkIcon: require('@/assets/menu/dark/FQA.svg'),
				// 	lightIcon: require('@/assets/menu/FQA.svg'),
				// 	url: '/app/FAQ',
				// },
			]
		},
	},
	mounted () {
		if (!this.is_mobile) {
			this.drawer = true
		}
		this.$nextTick(() => {
			const clipboard = new ClipboardJS('.copy-btn')

			clipboard.on('success', (e) => {
				this.$toast.success(this.$t('Replication success'))
				e.clearSelection()
			})

			clipboard.on('error', (e) => {
				this.$toast.error(this.$t('Copy failure'))
			})
		})
	},
	methods: {
		...mapMutations(['setADDRESS', 'SET_LANGUAGE']),
		languageTap ({ value }) {
			this.language = value
			SessionStorage.set('LANGUAGE', value)
			this.$i18n.locale = this.languageList[value]
			this.SET_LANGUAGE(this.languageList[value])
		},
		logout () {
			this.setADDRESS(null)
			this.account_dialog = false
			// window.location.reload()
		},
		_to (url) {
			this.$router.push(url)
		},
		toggleMenu () {
			this.drawer = !this.drawer
		},
	},
}
</script>

<style lang="scss"></style>
